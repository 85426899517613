import { Card, coreThemeV2 } from "@kolmeo/ui-components";
import { Text, Box } from "@kolmeo/ui-core";
import spacing from "@kolmeo/ui-core/src/Theme/spacing";
import { featureList, useFeature } from "@src/lib/FeatureFlags";

const CardWithTitle = ({
  children,
  cardTitle,
  secondaryComponent,
  squashToContent = false
}: { cardTitle: string; secondaryComponent?: JSX.Element; squashToContent?: boolean } & React.PropsWithChildren) => {
  const { palette, sizing } = coreThemeV2;
  const showHelpfulResources = useFeature(featureList.showKolmeoPlusHelpfulResources);
  return (
    <Card
      backgroundColor="white"
      flex={squashToContent ? undefined : 1} // Delete this to squash all the tiles height
      borderColor={palette.border.default}
      borderWidth={1}
      borderRadius={sizing.size20}
      padding={sizing.size20}
      paddingLeft={!showHelpfulResources?.enabled ? spacing.spacing24 : undefined}
      marginStart={showHelpfulResources?.enabled ? spacing.spacing8 : undefined}
    >
      <Box justifyContent="space-between" flexDirection={"row"} alignContent="center">
        <Text variant="textHeavy16" color={palette.black} mb={sizing.size12}>
          {cardTitle}
        </Text>
        {secondaryComponent && secondaryComponent}
      </Box>

      {children}
    </Card>
  );
};

export default CardWithTitle;
