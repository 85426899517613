import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { SectionList } from "react-native";
import { useSharedValue } from "react-native-reanimated";
import { Box, Text, TouchableOpacity, useTheme } from "@kolmeo/ui-core";
import DropdownTrigger from "./components/DropdownTrigger";
import DropdownContentContainer from "./components/DropdownContentContainer";
const GroupedDropdownMenu = ({ data, selected, selectedHandler, renderItem, isSelectedItemInput = false, isOpenCallback, borderStyles, triggerProps, label = "Please make a selection", overlay = false, optionsContainerProps, }) => {
    const sectionListRef = useRef(null);
    const y = useSharedValue(0);
    const { palette } = useTheme();
    const scrollHandler = (event) => {
        if (event?.nativeEvent?.contentOffset.y > 0) {
            y.value = event?.nativeEvent?.contentOffset.y;
        }
    };
    const isSingleItem = data?.length === 1 && data[0]?.data?.length === 1 ? true : false;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [triggerHeight, setTriggerHeight] = useState(0);
    const mainBoxRef = useRef(null);
    const toggleDropDown = () => setIsDropdownOpen(!isDropdownOpen);
    useEffect(() => {
        if (isOpenCallback) {
            isOpenCallback(isDropdownOpen);
        }
    }, [isDropdownOpen, isOpenCallback]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (mainBoxRef.current && !mainBoxRef.current.contains(event.target)) {
                setTimeout(() => {
                    setIsDropdownOpen(false);
                }, 200);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleSelectWrapper = (item) => {
        selectedHandler(item);
        toggleDropDown();
    };
    const renderItemWithTouch = (item) => renderItem ? (_jsx(TouchableOpacity, { onPress: () => {
            handleSelectWrapper(item.item);
        }, style: { paddingRight: 20 }, children: renderItem(item) })) : null;
    const filteredSections = data
        .map((section) => ({
        ...section,
        data: section.data.filter((item) => item !== selected),
    }))
        .filter((section) => section.data.length > 0);
    return (_jsxs(Box, { position: "relative", overflow: !overlay ? "hidden" : undefined, zIndex: overlay ? 999 : "auto", ref: mainBoxRef, children: [_jsx(Box, { ...borderStyles, children: _jsx(DropdownTrigger, { selected: selected, label: label, renderItem: ({ item, index, separators }) => renderItem({ item, index, separators }), isSelectedItemInput: isSelectedItemInput, isDropdownOpen: isDropdownOpen, toggleDropDown: toggleDropDown, triggerProps: triggerProps, setTriggerHeight: setTriggerHeight, isSingleItem: isSingleItem }) }), isDropdownOpen ? (_jsx(DropdownContentContainer, { overlay: overlay, triggerHeight: triggerHeight, optionsContainerProps: optionsContainerProps, children: _jsx(SectionList, { ref: sectionListRef, sections: filteredSections, keyExtractor: (item, index) => `${item}-${index}`, renderItem: renderItemWithTouch, contentContainerStyle: { paddingHorizontal: "16px", paddingTop: "8px" }, renderSectionHeader: ({ section: { title } }) => (_jsx(Box, { children: _jsx(Text, { variant: "label14Regular", color: palette.text.secondary, children: title }) })), onScroll: scrollHandler, SectionSeparatorComponent: () => _jsx(Box, { height: "8px" }), scrollEventThrottle: 16 }) })) : null] }));
};
export default GroupedDropdownMenu;
