export const spacing = {
  spacing4: 4,
  spacing8: 8,
  spacing12: 12,
  spacing16: 16,
  spacing20: 20,
  spacing24: 24,
  spacing32: 32,
  spacing40: 40,
  spacing64: 64,
  spacing80: 80,
};

export type Spacing = { [key in keyof typeof spacing]: number };
export default spacing;
