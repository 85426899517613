import { Icon } from "@kolmeo/ui-components";
import { Box } from "@kolmeo/ui-core";
import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, ViewStyle, TextStyle } from "react-native";
interface ToastProps {
  headline?: string;
  message?: string;
  onHide: () => void;
  showCloseIcon?: boolean;
  showRightIcon?: boolean;
  textStyles?: {
    headline?: TextStyle;
    message?: TextStyle;
  };
  containerStyles?: ViewStyle;
}
const Toast: React.FC<ToastProps> = ({
  headline,
  message,
  onHide,
  showCloseIcon = true,
  showRightIcon = false,
  textStyles = {},
  containerStyles = {}
}) => {
  return (
    <Box style={[styles.toastContainer, containerStyles]}>
      {/* Left Section: Headline with optional right icon */}
      <View
        style={{
          position: "absolute",
          backgroundColor: "#00BF4F",
          width: 10,
          height: 60,
          left: 0,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8
        }}
      ></View>
      <View style={styles.columnContainer}>
        {/* Headline and Icon in the same row */}
        <View style={styles.headlineRow}>
          {showRightIcon && <Icon type="check-circle" size={24} color="#00993F" />}
          <View style={styles.textContainer}>
            {headline && <Text style={[styles.toastHeadline, textStyles.headline]}>{headline}</Text>}
            {message && <Text style={[styles.toastText, textStyles.message]}>{message}</Text>}
          </View>
        </View>
      </View>
      {/* Close "X" icon */}
      {showCloseIcon && (
        <TouchableOpacity onPress={onHide} style={styles.closeIconContainer}>
          <Icon type="close" size="xxs" />
        </TouchableOpacity>
      )}
    </Box>
  );
};
const styles = StyleSheet.create({
  toastContainer: {
    padding: 16,
    borderRadius: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    top: 15,
    right: 15,
    elevation: 3,
    borderWidth: 1,
    borderColor: "#c3e6cb",
    backgroundColor: "#cef2de",
    height: 60
  } as ViewStyle,
  columnContainer: {
    flexDirection: "column",
    flex: 1, // Ensures the text takes available space
    left: 5
  } as ViewStyle,
  headlineRow: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1 // Ensures it takes up the remaining space
  } as ViewStyle,
  textContainer: {
    marginLeft: 10,
    flex: 1, // Ensures the text takes up remaining space
    flexWrap: "wrap", // Wraps text if necessary
    maxWidth: "90%" // Limits the width to prevent overflow
  } as ViewStyle,
  toastHeadline: {
    color: "#155724",
    fontSize: 16,
    fontWeight: "bold",
    flexShrink: 1 // Prevents headline from overflowing
  } as TextStyle,
  toastText: {
    color: "#155724",
    fontSize: 14,
    marginTop: 16,
    flexShrink: 1,
    flexWrap: "wrap"
  } as TextStyle,
  boldText: {
    fontWeight: "bold"
  } as TextStyle,
  closeIconContainer: {
    marginLeft: 8,
    marginBottom: 6
  } as ViewStyle,
  closeIconText: {
    color: "#1E1890",
    fontSize: 20
  } as TextStyle
});
export default Toast;
